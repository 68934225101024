.banner {
  min-height: max-content;
  height: fit-content;
}
.isWithBackgroundImage {
  background-color: transparent !important;
}

.horizontalCard {
  min-width: 640px !important;
  // min-width: 300px;
}
.desktopVerticalCard {
  min-width: 448px;
}
.mobileVerticalCard {
  min-width: 256px !important;
}

.homePageSectionContainers {
  display: grid !important;
  gap: 32px;
  overflow: visible !important;
  // margin-top: -28px !important;
  // padding-top: 32px;
  // padding-bottom: 32px;
  // @media (min-width: 768px) {
  //   max-width: 560px;
  // }

  // @media (min-width: 1000px) {
  //   max-width: calc(280px * 3);
  // }

  padding-top: 0px;
  @media (min-width: 1200px) {
    gap: 56px;
    // max-width: calc(280px * 4);
  }

  // @media (min-width: 1500px) {
  //   max-width: calc(280px * 5);
  // }
}

// .horizontalCardImage {
//   min-width: 288px !important;
//   // min-width: 300px;
// }

.pageBodyGridContainerClassName {
  // position: static;
  // z-index: 2;
  margin-top: -2px;
  // top: 430px;
  &.isDense {
    // top: 505px;
  }
}

// .firstScrollingSection {

// }

// .isFirst {
//   z-index: 9;
//   position: relative;

//   @media screen and (min-width: 960px) {
//     margin-top: -40px !important;
//     // z-index: 9;
//     // position: relative;
//   }

// }

.test2 {
  z-index: 0;
  // position: relative;
}

.testClass {
  z-index: 999;
  // position: fixed;
}

.scrollingSectionWithBackground {
  // margin-left: -200px;
  // margin-left: $margin-by-background-scrolling !important;
  // padding-left: $padding-left-by-background-scrolling !important;

  // margin-left: calc(0 - $margin-left-in-scrolling)px !important;
  // margin-left: -100px !important;
  // background-position:center;
  // background-size: cover;
  // background-position: left !important;

  // width: 100vw;
  // grid-column: 1/7 !important;
  // @media screen and (min-width: 960px) {
  // background-position: right -250px;

  // }
}

.generalScrollingSectionClass {
  // width: auto;
  // margin-right: -#{$test-meir}px;
  // margin-right: $margin-by-background-scrolling !important;

  // position: absolute;
  // left: auto;
  // background-color: #FFE4E1;

  // right: auto;
  // margin-left: -200px !important;
  // width: 1848px !important;
}

// .cardsClass {

// min-width: 1400px !important;
// z-index: 999;
.editOrderMessageCard {
  @media screen and (max-width: 720px) {
    margin-top: 16px;
    min-height: 430px;
  }

  margin-top: -60px;
}

// }
